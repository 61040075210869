@use '~scss/settings' as *;

// @media (min-width: 1240px) {
//   .job-preview-hero {
//     min-height: 800px;
//   }
// }

@media (min-width: 1100px) and (max-width: 1440px) {
  .job-preview-hero {
    padding: 80px 40px;

    &__circle {
      transform: translate(-30%, -50%);
    }
  }
}

@media (min-width: 768px) and (max-width: 1099px) {
  .job-preview-hero {
    &__job-information {
      max-width: 70%;
    }
    &__circle {
      transform: translate(-20%, -50%);
    }
  }
}

@include phablet {
  .job-preview-hero {
    @include flex(column);

    gap: 18px;
    padding: 80px 20px;
    overflow: hidden;
    height: unset;

    &__job-information {
      max-width: unset;

      &__schools {
        max-width: 50%;
      }
    }

    &__title {
      @include font-size(44px, 52px);
      width: unset;

      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__circle {
      display: none;
    }
  }
}
