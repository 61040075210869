@use '~scss/settings' as *;

.searchality-profile-reference {
  .email-icon {
    path {
      stroke: $color-secondary-light-grey;
    }
  }

  &__section {
    @include flex(column);

    gap: 24px;

    p {
      overflow-wrap: break-word;
    }

    > div {
      @include flex(column);

      gap: 4px;
    }

    .title {
      @extend %paragraph-subtitle;
    }

    .subtitle {
      @extend %paragraph-subtitle-small;

      color: $color-secondary-light-grey;
    }

    .description {
      @extend %input-descriptive;

      color: $color-secondary-light-grey;
    }
  }
}
