@use '~scss/settings' as *;

@media (max-width: 850px) {
  .job-preview-section {
    @include flex(column);

    gap: 32px;

    &__left {
      width: unset;
      &__section-number {
        @include font(44px, 52px, 700);
      }

      &__title {
        @include font(32px, 38px, 900);
      }
    }

    &__right {
      width: unset;
    }
  }
}
