@use '~scss/settings' as *;

.searchality-profile-work-profile {
  &__inline-pdfs {
    @include flex(row, flex-start, flex-start);

    > * {
      flex-basis: 50%;
    }
  }

  .profile-pdf-section {
    max-width: 450px;
  }
  .iframe-video-wrapper {
    iframe.loading {
      pointer-events: none;
      background-color: $color-secondary-light-grey;
    }
  }
}

@media (max-width: 900px) {
  .searchality-profile-work-profile {
    &__inline-pdfs {
      @include flex(column, flex-start, stretch);

      > * {
        flex-basis: unset;
      }
    }
  }
}
