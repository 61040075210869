@use '~scss/settings' as *;

.publication-link {
  @include flex(row, flex-start, center);

  border-radius: $border-radius;
  border: 1px solid $color-primary-light-teal;
  background: $color-white;
  color: $color-secondary-light-grey;
  gap: 15px;
  padding: 10px;
  max-width: 500px;

  > img {
    width: 31px;
  }

  &__info {
    flex: 1;
    width: calc(100% - 107px);

    &__name {
      @extend %input-descriptive;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__additional {
      @extend %paragraph-descriptive;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: 6px;
    }
  }
}
