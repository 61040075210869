@use '~scss/settings' as *;

.searchality-vacancy-card {
  @include flex(row, space-between, stretch);
  @include card;

  min-height: 170px;

  svg {
    @include size(22px);
  }

  ul {
    list-style-type: none;
    padding: 0;
    li {
      &:last-of-type {
        margin-bottom: 0;
      }
      p {
        @include font(16px, 19px);

        color: $color-text-light-grey;
      }
    }
  }

  &:hover {
    background: $color-grey-f7;
    border: 1px solid $color-primary-teal;

    .actions-container {
      @include flex(row, flex-end, center);
      @include gap(unset);

      gap: 30px;
      color: $color-secondary-light-grey;
      align-self: flex-end;

      > svg {
        cursor: pointer;
      }
    }
  }

  &__left {
    @include flex(column, flex-start, flex-start);

    flex: 1;
    max-width: 70%;

    &__school-type {
      @include flex(row, flex-start, center);

      @extend %paragraph-subtitle-small;
      @extend %opensans;

      gap: 10px;
      color: $color-secondary-light-grey;
    }

    &__school-name {
      max-width: 50%;
      > ul {
        > li {
          > p {
            color: $color-primary-dark-blue;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }

    &__position {
      @extend %paragraph-subtitle;

      margin-top: 4px;
    }

    &__type {
      @extend %paragraph-descriptive;

      color: $color-text-light-grey;
      margin-top: 8px;
      margin-bottom: 44px;
    }
  }

  &__right {
    @include flex(column, space-between, flex-start);

    > div {
      @include flex(row, flex-start, center);
      @include font-size(12px, 14px);
      @include gap(16px);

      align-self: flex-end;

      > label {
        font-weight: 300;
        color: $color-text-light-grey;
      }
    }

    &__status-date {
      @include font-size(16px, 26px);

      color: $color-text-light-grey;
    }

    .actions-container {
      display: none;
    }
  }
}

.tooltip-information.actions-container__tooltip {
  padding: 16px;
}
