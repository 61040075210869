@use '~scss/settings' as *;

.candidate-profile-rtw {
  &--work-permit {
    @include flex(column);

    gap: 13px;
    padding: 19px 20px;
    border-radius: $border-radius-20;
    border: 1px solid $color-primary-light-teal;
    background-color: $color-white;

    > div {
      @include flex(row, space-between, center);

      p {
        @extend %paragraph-subtitle;

        color: $color-primary-dark-blue;
        word-break: break-all;
      }

      svg {
        @include size(20px);
        path {
          fill: $color-primary-dark-blue;
        }
      }
    }
  }

  width: 100%;

  > div {
    > div {
      width: 100%;
    }
  }

  summary {
    @include flex(column, flex-start, flex-start);

    width: 100%;
    padding: 20px;
    background-color: $color-white;
    border: 1px solid $color-primary-light-teal;
    border-radius: $border-radius-20;

    .candidate-qualification {
      align-items: flex-start;
    }

    svg {
      transition: transform 0.4s;
    }
  }
  .nd-collapsible__container {
    > div {
      width: 100%;
    }
  }

  &__content {
    @include flex(column);

    gap: 28px;
    width: 100%;
    border: 1px solid $color-primary-light-teal;
    border-bottom-left-radius: $border-radius-20;
    border-bottom-right-radius: $border-radius-20;
    background: $color-white;
    border-top: unset;
    padding: 26px 28px 38px 20px;
  }

  .collapsabile {
    &__title {
      @include flex(row, space-between, center);

      width: 100%;
      margin-bottom: 20px;

      p {
        @extend %paragraph-subtitle;

        color: $color-primary-dark-blue;
        word-break: break-all;
      }
    }
  }

  &[open] {
    summary {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      svg {
        transform: rotate(180deg);
      }
    }
  }
}
