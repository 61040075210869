@use '~scss/settings' as *;

.job-preview-boarding-section {
  &__detailed-container {
    @include flex(column);

    margin-bottom: 20px;
    gap: 16px;
    a {
      @include flex(row, flex-start, center);

      @extend %input-descriptive;
      background-color: $color-white;
      gap: 10px;
      color: $color-secondary-light-grey;
      width: fit-content;
      padding: 8px;
      border-radius: 73px;
    }
  }

  > ul:first-of-type {
    margin-bottom: 20px;
  }

  > ul {
    margin-top: 12px;
  }
}
