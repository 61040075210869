@use '~scss/settings' as *;

.searchality-profile-education {
  &__section {
    @include flex(column);

    gap: 8px;

    p {
      overflow-wrap: break-word;
    }

    .title {
      @extend %heading4;

      color: $color-secondary-light-grey;
    }

    .subtitle {
      @extend %input-descriptive;

      color: $color-primary-dark-blue;
    }

    .description {
      @extend %input-descriptive;

      color: $color-secondary-light-grey-2;
    }
  }

  &__empty-state {
    @include flex(row, center, center);

    .template-illustration__image-container {
      @include size(200px, 170px);
    }

    h4 {
      color: $color-secondary-light-grey;
      white-space: pre-wrap;
      text-align: center;
    }
  }
}
