@use '~scss/settings' as *;

.profile-pdf-section {
  .profile-section__title {
    svg {
      fill: $color-secondary-light-grey;
    }
  }
  &__file-container {
    @include flex(column, center, center);

    width: 100%;
    max-width: 400px;
    background-color: $color-grey-fc;
    border-radius: $border-radius-20;
    padding: 24px;
    gap: 12px;

    &__iframe-container {
      @include flex(row, center, center);

      position: relative;
      width: 190px;
      height: 190px;
      overflow: hidden;
      border: 1px solid $color-grey-e6;
      border-radius: $border-radius-5;

      &__iframe {
        min-height: 200px;
        overflow: hidden;
      }

      &__overlay {
        position: absolute;
        inset: 0;
        z-index: 1;
        background-color: transparent;
      }
    }

    &__text {
      @include flex(row, center, center);

      gap: 12px;

      span {
        @include flex(column);

        gap: 8px;

        p:first-of-type {
          @extend %input-descriptive;

          text-decoration: underline;
          color: $color-primary-dark-blue;
          cursor: pointer;
        }

        p:last-of-type {
          @extend %paragraph-descriptive;
        }
      }
    }
  }
}
