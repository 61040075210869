@use '~scss/settings' as *;

.job-preview {
  @include flex(column);

  padding: 48px 48px 160px;
  background-color: $color-grey-f7;

  width: 100%;
  max-width: 1980px;
  margin: 0 auto;

  &__detailed-container {
    @include flex(column);

    margin-top: 32px;
    gap: 16px;
    a {
      @include flex(row, flex-start, center);

      @extend %input-descriptive;
      background-color: $color-white;
      gap: 10px;
      color: $color-secondary-light-grey;
      width: fit-content;
      padding: 8px;
      border-radius: 73px;
    }
  }

  li {
    overflow-wrap: break-word;
  }

  .role-description-container {
    overflow-wrap: break-word;

    span,
    p {
      background-color: transparent !important;
    }
  }

  &__section-container {
    @include flex(column);

    gap: 100px;
    padding: 0 80px;

    &__section-subtitle {
      @extend %paragraph-subtitle;

      margin: 12px 0;
    }
  }

  &__button-container {
    @include flex;

    gap: 12px;
  }
}
