@use '~scss/settings' as *;

.hiring-preferences-settings {
  @include flex(row, flex-start, flex-start);

  gap: 40px;
  flex-wrap: wrap;

  > * {
    flex: 1 1 40%;
  }
}
