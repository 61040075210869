@use '~scss/settings' as *;

.profile-personal-information {
  &__section {
    @include flex(column);

    gap: 4px;

    &__title {
      @extend %paragraph;

      color: $color-secondary-light-grey;
    }

    &__value {
      @extend %paragraph-subtitle;
    }
  }
}
