@use '~scss/settings' as *;

.job-preview-hero {
  @include flex(column, center);

  gap: 32px;
  width: 100%;
  background-color: $color-white;
  border-radius: 34px;
  position: relative;
  padding: 160px 0 160px 10%;
  overflow: hidden;
  margin-bottom: 100px;
  height: 75vh;

  &__logo {
    width: 100%;
    max-width: 160px;
    height: auto;
    background-size: cover;
    margin-top: 20px;
    margin-bottom: -12px;
  }

  &__title {
    width: 90%;
    color: $color-text-dark-grey;
    word-break: break-word;
    position: relative;
    z-index: 1;
  }

  &__job-information {
    @include flex;

    max-width: 50%;
    gap: 22px;

    &__schools {
      @extend %paragraph-subtitle-small;

      color: $color-text-dark-grey;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 70%;
    }

    &__employement-type {
      @include flex(row, center, center);

      @extend %paragraph-subtitle-small;

      color: $color-primary-dark-blue;
      position: relative;

      &::before {
        @include size(6px);

        content: '';
        position: absolute;
        background-color: $color-grey-d9;
        border-radius: 50%;
        top: 50%;
        left: -11px;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__action-buttons {
    @include flex;

    gap: 12px;
    flex-wrap: wrap;

    button.link-copied {
      border-color: $color-primary-teal;
      color: $color-primary-teal;

      svg {
        g {
          path {
            fill: $color-primary-teal;
          }
        }
      }
    }
  }

  &__circle {
    @include size(1200px);

    position: absolute;
    border-radius: 50%;
    background-color: rgba(189, 216, 217, 0.63);
    top: 50%;
    left: 100%;
    transform: translate(-50%, -50%);
  }
}
