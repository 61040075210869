@use '~scss/settings' as *;

.profile-non-academic-work {
  @include flex(row, flex-start, flex-start);

  > * {
    flex-basis: 50%;
  }
}

@media (max-width: 900px) {
  .profile-non-academic-work {
    @include flex(column, flex-start, flex-start);

    > * {
      flex-basis: unset;
    }
  }
}
